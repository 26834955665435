import moment from 'moment';
const _ = require('lodash');
const debug = require('debug')('reducers:conversation')

import {
  GET_CONVERSATIONS_COURSES,
  GET_CONVERSATIONS, GET_CONVERSATION_DETAIL, UPDATE_CONVERSATION, SET_CONVERSATION_CURRENT,
  SET_CONVERSATION_ADD_MOD, GET_MODS, GET_CONVERSATION_MOD_REMOVE,
  SEND_MESSAGE, SEND_VIDEO, SEND_IMAGE, RECEIVED_MESSAGE, ON_READ,
  GET_USER_INFO,
  POST_CREATE_CONVERSATION_REMIND, DEL_CONVERSATION_REMIND, SET_CONVERSATION_NOTE
} from '../';


const initialState = {
  isFetching: false,
  unRead: null,
  conversations: [],
  myList: [],
  currentConversation: false,
  mods: [],
  courses: [],
  current: {},
};


export default (state = initialState, action) => {

  switch (action.type) {
    case GET_USER_INFO.REQUEST:
      return {
        ...state,
        isFetching: true,
        user: {}
      };

    case GET_USER_INFO.SUCCESS:
      return helper.currentConversation.update({
        ...state,
        isFetching: false,
      }, { 'user': action.result });

    case GET_USER_INFO.FAIL:
      return {
        ...state,
        isFetching: false,
        user: {}
      };

    case GET_CONVERSATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case POST_CREATE_CONVERSATION_REMIND.SUCCESS:
      // console.log(action.result.data)
      return helper.currentConversation.update(state, { 'conversation_remind': action.result.data });
    case GET_CONVERSATIONS.SUCCESS:
      let current = { ...state.current };
      let conversations = []
      if (action.mode == 'auto') {
        conversations = _.unionBy(action.data, state.conversations, "id");
        current = {
          current_page: action.paginate.current_page,
          last_page: action.paginate.last_page,
        };
      }
      else if (action.mode == 'more') {
        conversations = _.unionBy(state.conversations, action.data, "id");
        current = {
          current_page: action.paginate.current_page,
          last_page: action.paginate.last_page,
          // total: action.result.conversations.total
        };
      }
      else {
        conversations = action.data
        current = {
          current_page: action.paginate.current_page,
          last_page: action.paginate.last_page,
          // total: action.conversations.total
        };
      }

      return helper.conversations.set({
        ...state,
        isFetching: false,
        unRead: action.un_read,
      }, conversations, current)

    case GET_CONVERSATIONS.FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case GET_MODS.SUCCESS:
      return helper.mods.set(state, action.result)

    case SET_CONVERSATION_ADD_MOD.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case SET_CONVERSATION_ADD_MOD.SUCCESS:
      return helper.currentConversation.update({
        ...state,
        isFetching: false,
      }, { 'users': action.result.users })

    case SET_CONVERSATION_NOTE.SUCCESS:
      return helper.currentConversation.setNote(state, action.result.note)

    case GET_CONVERSATION_MOD_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_CONVERSATION_MOD_REMOVE.SUCCESS:
      return helper.currentConversation.update({
        ...state,
        isFetching: false,
      }, { 'users': action.result.users })

    case SET_CONVERSATION_CURRENT.SUCCESS:
      return helper.currentConversation.set(state, action.conversation)

    case GET_CONVERSATIONS_COURSES.SUCCESS:
      // console.log('cccc',action);
      return {
        ...state,
        isFetching: true,
        courses: action.result
      };

    // case GET_CONVERSATION_DETAIL.REQUEST:
    //   return {
    //     ...state,
    //     isGettingConversationDetail: true,
    //     isGettingConversationDetailSuccess: false,
    //   };
    case GET_CONVERSATION_DETAIL.SUCCESS:
      // console.log('currentConversation', action.result.conversation);
      const conversation = { ...state.currentConversation, users: action.result.conversation.users, conversation_remind: action.result.conversation.conversation_remind }
      return helper.currentConversation.set(state, conversation);

    case GET_CONVERSATION_DETAIL.FAIL:
      return {
        ...state,
        isGettingConversationDetail: false,
        isGettingConversationDetailSuccess: false
      };

    case UPDATE_CONVERSATION.REQUEST:
      return {
        ...state,
        isUpdatingConversation: true,
        isUpdatingConversationSuccess: false
      };
    case UPDATE_CONVERSATION.SUCCESS:
      return {
        ...state,
        isUpdatingConversation: false,
        isUpdatingConversationSuccess: true
      };
    case UPDATE_CONVERSATION.FAIL:
      return {
        ...state,
        isUpdatingConversation: false,
        isUpdatingConversationSuccess: false
      };

    case ON_READ.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ON_READ.SUCCESS:
      state = helper.currentConversation.setRead(state);
      return helper.myList.push(state, state.currentConversation, (myList) => {
        return {
          ...state,
          isFetching: false,
          myList: myList,
          // isRequestonRead: false,
          // isonReadSuccess: true,
          // success: null,
        };
      })
    // case ON_READ.FAIL:
    //   return {
    //     ...state,
    //     isRequestonRead: false,
    //     isonReadSuccess: false
    //   };


    case SEND_MESSAGE.REQUEST:
      return {
        ...state,
        isFetching: true,
      };


    case SEND_MESSAGE.SUCCESS:
      return helper.myList.push(state, state.currentConversation, (myList) => {
        return {
          ...state,
          isFetching: false,
          myList: myList,
          success: null,
        };
      })

    case SEND_MESSAGE.FAIL:
      return {
        ...state,
        isFetching: false,
        success: false,
        errorMessage: action.result.error
      };


    default:
      return state;
  }
};

const helper = {
  mods: {
    set: (state, mods) => {
      return {
        ...state,
        mods: mods
      };
    }
  },
  conversations: {
    set: (state, conversations, current) => {
      return {
        ...state,
        conversations: conversations,
        current: current
      };
    },
    findById: (conversations, id, cb) => {
      return conversations.map((item, index) => {
        if (item.id == id)
          return cb(item, index)
        return item
      })
    }
  },
  currentConversation: {
    set: (state, currentConversation) => {
      return {
        ...state,
        isGettingConversationDetail: false,
        isGettingConversationDetailSuccess: true,
        // latest_unit: { ...action.result.latest_unit },
        // child_days: { ...action.result.child_days },
        // is_easyone: action.result.is_easyone,
        currentConversation: currentConversation
      }
    },
    update: (state, params) => {
      let currentConversation = { ...state.currentConversation }
      Object.keys(params).forEach(i => {
        currentConversation[i] = params[i];
      })
      return { ...state, currentConversation: currentConversation }
    },
    setRead: (state) => {
      let conversations = [...state.conversations]
      let currentConversation = { ...state.currentConversation }
      currentConversation.mess_after_mod = 0
      conversations = helper.conversations.findById(conversations, currentConversation.id, (item, index) => {
        item.mess_after_mod = 0
        return item;
      })
      return {
        ...state,
        conversations: conversations,
        currentConversation: currentConversation
      }
    },
    setNote: (state, note) => {
      let conversations = [...state.conversations]
      let currentConversation = { ...state.currentConversation }
      currentConversation.note = note
      conversations = helper.conversations.findById(conversations, currentConversation.id, (item, index) => {
        item.note = note
        return item;
      })
      return {
        ...state,
        conversations: conversations,
        currentConversation: currentConversation
      }
    }
  },
  myList: {
    push: (state, currentConversation, callback) => {
      currentConversation.updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
      let myList = [...state.myList]
      let found = false;
      myList = myList.map(item => {
        if (item.id == currentConversation.id) {
          found = true
          return currentConversation
        }
        return item
      }).sort((a, b) => moment(b.updated_at) - moment(a.updated_at))
      if (!found)
        myList.push(currentConversation)
      return callback(myList)
    }
  }
}