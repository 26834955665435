import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../redux/actions/login'
import { CDN_URL } from '../configs/API';

class Drawer extends Component {

  render() {
    const { user } = this.props;
    if (!user) {
      return <Redirect to="/login" push />
    }

    const { pathname } = this.props.location;
    return (
      <div className="layout">


        <nav className="navigation">
          <div className="nav-group">
            <ul>
              <li className="logo">
                <a href="/">
                  <img src='./images/logo.png' width="100%" />
                </a>
              </li>
              <li>
                <a className={pathname == '/conversation' ? "active" : ''} data-navigation-target="chats" href="/conversation"
                  data-toggle="tooltip" title="" data-placement="right" data-original-title="Chats">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="feather feather-message-circle">
                    <path
                      d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                    </path>
                  </svg>
                {this.props.unRead && <span className="badge badge-warning">{this.props.unRead}</span>}
                </a>
              </li>
              {/* <li>
                <a className={pathname == '/support' ? "active" : ''} data-navigation-target="friends" href="/support"
                  title="Support" data-placement="right" data-original-title="Support">
                  <span className="badge badge-danger"></span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="feather feather-user">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </a>
              </li> */}
              <li>
               
              </li>
              <li className="brackets">
              
              </li>
              <li>
               
              </li>
              <li data-toggle="tooltip" title="" data-placement="right" data-original-title={user.fullname}>
                <a href="#" data-toggle="dropdown">
                  <figure className="avatar">
                    <img src={user && CDN_URL + ((user.image!=null)?user.image:'/noavatar.png')} className="rounded-circle" alt="image" />
                  </figure>
                </a>
                <div className="dropdown-menu">
                  <span className="dropdown-header">{user.name}</span>
                  <div className="dropdown-divider"></div>
                  <a href="#" onClick={this.props.logout} className="dropdown-item text-danger">Logout</a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div className="content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  unRead: state.conversation.unRead
});
const mapDispatchToProps = {
  logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drawer);