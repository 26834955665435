import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FacebookSelector, FacebookCounter } from '@charkour/react-reactions';
import { Popover } from 'react-tiny-popover'

import { CDN_URL } from '../configs/API';
import Utils from '../utils';
import helper from '../helpers';
import { firebase, onListenFirestore } from '../helpers/firebase';

import AvatarComponent from './avatar'
import moment from '../configs/moment';
class MessageItem extends Component {

    constructor(props) {
        super(props);
        this.checkTimeToFold = 300 //10 minutes
        this.state = {
            Popover: false,
            showTime: false
        };
    }
    componentDidMount = () => {
    }
    componentDidUpdate = () => {
    }

    checkPreviousMessage = () => {
        const { prevMessage, message } = this.props;

        if (prevMessage)
            if (prevMessage.type != 'system')
                if (prevMessage.senderId == message.senderId)
                    if (message.timestamp - prevMessage.timestamp <= this.checkTimeToFold)
                        return true;
        return false;
    }
    checkNextMessage = (force = false) => {
        if (force)
            return false;
        const { nextMessage, message } = this.props;

        if (nextMessage)
            if (nextMessage.senderId == message.senderId)
                if (nextMessage.timestamp - message.timestamp <= this.checkTimeToFold)
                    return true;
        return false;
    }

    renderMessageMedia = () => {
        const { message } = this.props;

        let media = message.media || [];
        if (message.video) media.push(message.video);
        if (message.image) media.push(message.image);
        if (media.length == 0)
            return '';
        return <div key="media" className="message-media">{media.map((url, index) => {
            if (url[0] != '/')
                url = '/' + url;
            return helper.isVideo(url) ?
                <a key={`media_${index}`} className="p-1" target="_blank" data-fancybox="gallery" href={CDN_URL + url}><video src={CDN_URL + url} width="320" height="240" controls /></a> :
                <a key={`media_${index}`} className="p-1" target="_blank" data-fancybox="gallery" href={CDN_URL + url}><img src={CDN_URL + url} style={{ 'maxHeight': "150", 'maxWidth': "320" }} /></a>;
        })}
        </div>
    }
    renderBirthdayObj = () => {
        const { message } = this.props;
        if (message.birthday_obj != undefined)
            return message.birthday_obj.birth_string
        return message.birth_string
    }
    renderSystemMessage = () => {
        const { message } = this.props;

        return <div key={message.id} className="separator mb-2">
            <span>
                <span className="sender">{message.senderName}</span> {message.content} - {Utils.formatDateTimeShort(message.time)}</span>
        </div>
    }
    renderMessageUnit = () => {
        const { message } = this.props;
        if (message.latest_unit_id == undefined || message.latest_unit_id == "") return;
        return [<hr key="0" />, <div key="1"><i className="badge badge-info">Unit</i> {message.latest_unit_name}</div>]
    }

    renderMessageAttachment = (attachments = []) => {
        console.log('attachment', typeof (attachments), attachments)
        if (typeof (attachments) != 'object' || attachments.length == 0) return;
        attachments = attachments.filter(item => {
            // console.log('attachments', item, this.props.currentConversation.course_id)
            if (item.value <= 0) return false
            if (item.type == 'course' && item.value == this.props.currentConversation.course_id) return false
            return true
        })
        if (attachments.length == 0) return
        return [<hr key="0" />, attachments.map((item, index) => {
            return <div key={index + 1}><i className="badge badge-info">{item.type}</i> {item.title}</div>
        })]
    }

    urlify(text) {
        const { message } = this.props;
        const links = message.links ? JSON.parse(message.links) : []
        links.forEach((link, index) => {
            text = text.replace(new RegExp('%link' + index + '%', "g"), '<a href="#" target="_blank">' + link.title + '</a>')
        })
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        })
    }

    renderAvatar = () => {
        const { currentUser, message, currentConversation } = this.props;
        return !this.checkPreviousMessage() && message.senderId !== currentUser.id &&
            <div className="message-avatar mb-2">
                <AvatarComponent userId={message.senderId} users={currentConversation.users} />
                <div>
                    <h5>{message.senderName}</h5>
                    <div className="time">
                        {this.renderBirthdayObj()}
                    </div>
                </div>
            </div>
    }

    getReactions = () => {
        const { reaction } = this.props.message
        if (reaction == undefined || reaction == null)
            return [];

        return reaction.map(item => {
            return { by: item.name, emoji: item.value }
        })
    }

    onReaction = (value) => {
        const { currentUser, message } = this.props;
        var reaction = [...message.reaction ?? [], {
            value: value,
            name: currentUser.name,
            created_at: moment().format('YYYY-MM-DD HH:mm:ss')
        }]

        firebase.firestore().doc(message.path).update({
            reaction: reaction
        }).then(() => {
            this.setState({ reaction: reaction });
        });
        this.setState({ Popover: false })
    }

    switchShowTime = () => {
        this.setState({
            showTime: !this.state.showTime
        })
    }

    renderTools = () => {
        const { currentUser, message } = this.props;
        var reactions = this.getReactions()
        return <div className="mt-1" key={message + '-tools'} style={{ 'display': 'flex', 'alignItems': 'flex-end', 'justifyContent': 'space-between' }}>
            <FacebookCounter counters={reactions} user={currentUser.name} />
            <div className="ml-2">
                {!this.checkNextMessage(this.state.showTime) && <span className="time mr-2">{Utils.formatDateTimeShort(message.time)}</span>}
                {message.senderId == currentUser.id && <a href="#" className="" onClick={() => this.props.onDelete(message)}>Xóa</a>}
            </div>
        </div>
    }
    renderMessageContent = () => {
        const { message } = this.props;
        return <div className="message-content">{[<p key={`content`} style={{ marginBottom: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: this.urlify(message.content, message) }} />,
        this.renderMessageMedia(message),
        this.renderMessageAttachment(message?.attachment),
        this.renderMessageUnit(message)]}</div>
    }
    renderDebug = () => {
        if (process.env.NODE_ENV !== 'development')
            return
        const { message } = this.props;
        return <div key="debug">
            <i className="badge badge-danger">Debug: {message.path} | {message.timestamp}</i>
        </div>
    }
    render = () => {
        const { currentUser, message } = this.props;

        if (message.type != undefined && message.type == 'system')
            return this.renderSystemMessage();


        if (message.senderId !== currentUser.id)
            return <div key={`msg_${message.id}`} onDoubleClick={this.switchShowTime} className="message-item">
                {this.renderDebug()}
                {this.renderAvatar()}
                {message.title && <div className="message-title"><strong>{message.title}</strong></div>}
                <div style={{ 'display': 'flex', alignItems: 'flex-end' }} key={message.id + '-msg'}>
                    {this.renderMessageContent()}
                    <Popover
                        isOpen={this.state.Popover}
                        positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                        content={<FacebookSelector onSelect={this.onReaction} iconSize={24} />}
                        onClickOutside={() => this.setState({ Popover: false })} >
                        <a href="#" onClick={() => this.setState({ Popover: true })}>
                            <i className="las la-smile m-1"></i>
                        </a>
                    </Popover>
                </div >
                {this.renderTools()}
            </div>
        return <div key={`msg_${message.id}`} className="message-item outgoing-message">
            {this.renderDebug()}
            {this.renderAvatar()}
            {this.renderMessageContent()}
            {this.renderTools()}
        </div>
    }
}


export default connect(
    // mapStateToProps,
    // mapDispatchToProps
)(MessageItem);