import axios from 'axios';
const debug = require('debug')('sagas')

axios.interceptors.request.use(request => {
  // if (process.env.NODE_ENV === 'development')
  // console.info(request);
  // Edit request config
  return request;
}, error => {
  console.error(error);
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  // debug(response.config.method.toUpperCase(), response.config.url, response.config.data || '', response.data)
  // Edit response config
  return response;
}, error => {
  console.error(error);
  return Promise.reject(error);
});


export function axiosGet(URL, config = {}) {
  return axios
    .get(URL, config)
    .then(response => {
      const { data } = response;
      if (response.status === 200) {
        return {
          success: true,
          data
        };
      }
      return {
        success: false,
        data
      };
    })
    .catch(error => ({
      success: false,
      errorMessage: error.response
    }));
}

export function axiosDelete(URL, config = {}) {
  return axios
    .delete(URL, config)
    .then(response => {
      const { data } = response;
      if (response.status === 200) {
        return {
          success: true,
          data
        };
      }
      return {
        success: false,
        data
      };
    })
    .catch(error => ({
      success: false,
      errorMessage: error.response
    }));
}
export function axiosPost(URL, post, config = {}) {
  return axios
    .post(URL, post, config)
    .then(response => {
      // const { data } = response;
      return response.data;
    })
    .catch(error => {
      if (error.response.data.message != undefined) {
        alert(error.response.data.message);
      }
      return {
        success: false,
        errorMessage: error
      }
    });
}

export function fetchPost(URL, post, apiToken, config = {}) {
  fetch(URL, {
    method: 'POST',
    redirect: 'error',
    body: JSON.stringify(post),
    headers: {
      Authorization: 'Bearer ' + apiToken
    }
  })
    .then(response => {
      // console.log(response);
      return response;
    })
    .catch(err => {
      console.error(err + ' url: ' + URL);
      return err;
    });
}

export function axiosPut(URL, post, config = {}) {
  return axios
    .put(URL, post, config)
    .then(response => {
      const { data } = response;
      if (response.status === 200 || response.status === 201) {
        return {
          success: true,
          data
        };
      }
      return {
        success: false,
        data
      };
    })
    .catch(error => ({
      success: false,
      errorMessage: error.response
    }));
}
