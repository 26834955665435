import React from 'react';

import { CDN_URL } from '../configs/API';
const getSized = ({ size: size }) => {
    switch (size) {
        case 'medium':
            return 'max_350'
        default:
            return 'max_40'
    }

}
const getUrl = props => {
    var { userId, user, users } = props;

    if (!user && users && userId)
        var user = users?.find(item => item.id == userId);

    if (user && user.image) {
        // https://cdn.poh.com.vn/resized/max_350/avatars/11260.jpg
        return CDN_URL + '/resized/' + getSized(props) + '/avatars/' + user.id + '.jpg';
    }

    return './images/noavatar.png'

}
const Avatar = props => <figure className={props.className ?? 'avatar'}>
    <img src={getUrl(props)} className="rounded-circle" alt="image" />
</figure>


export default Avatar