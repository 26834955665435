import firebase from "firebase/app";
import "firebase/firestore";
const debug = require('debug')('firebase')

var firebaseConfig = {
  apiKey: "AIzaSyBto832ienc5E3ZIe9wlJVqBuMzyEBJCoo",
  authDomain: "poh-vn.firebaseapp.com",
  databaseURL: "https://poh-vn.firebaseio.com",
  projectId: "poh-vn",
  storageBucket: "poh-vn.appspot.com",
  messagingSenderId: "463088467119",
  appId: "1:463088467119:web:18137e0f1e2bf8ac26e85a",
  measurementId: "G-K2T5WFCZRP"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.firestore.setLogLevel('debug');
const onListenFirestore = (doc, onSnapshot, onChange) => {
  debug('Firebase: initiald listener');
  //listen event for new message
  return doc.onSnapshot(querySnapshot => {
    onSnapshot(querySnapshot);
    // console.info('Firebase: query size', querySnapshot.size);
    querySnapshot.docChanges().forEach(onChange);

  }, err => {
    console.error('Firebase', `Encountered error: ${err}`);
  })
}

export { firebase, onListenFirestore };