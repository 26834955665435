import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextareaAutosize from 'react-textarea-autosize';
import { axiosPost } from '../redux/sagas/utils'


import Utils from '../utils';
import API, { CDN_URL, API_ROOT } from '../configs/API';
import { getUserInfo, getConversationDetail, getConversationModRemove, addModToConversation, saveNoteToConversation } from '../redux/actions/action_conversation';


import ConversationRemindComponent from './conversation-remind';
import Avatar from './avatar'
import { UserInfo, ChildSchedule, DeviceInfo, UserOrders, ChildrenInfo, ModsList } from './profile_tabs'
class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            note: '',
            addMod: false,
            mod_id: false,
            search: '',
            messages: []
        }
    }
    componentDidMount() {
        const { currentConversation } = this.props;
        if (currentConversation) {
            this.setState({
                note: this.props.currentConversation.note || ''
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.currentConversation != this.props.currentConversation) {
            this.setState({
                note: this.props.currentConversation.note || ''
            })
        }
    }


    onSaveNote = () => {
        const { currentConversation, user } = this.props;
        this.props.saveNoteToConversation({
            id: this.props.currentConversation.id,
            note: this.state.note
        })
    }

    onSearchMessage = () => {
        $("div").removeClass('highlight')
        const { currentConversation } = this.props;
        // axiosPost(API.CONVERSATION.SEARCH_MESSAGE.replace("{id}", currentConversation.id), {
        //     search: this.state.search
        // }).then(res => {
        //     console.log(res.data)
        //     this.setState({
        //         messages: res.data.messages
        //     })
        // })
        //search(this.props.messages, ["content"], this.state.search)
        const messages = this.props.messages.filter(item => item.content?.toLowerCase().indexOf(this.state.search?.toLowerCase()) != -1);
        this.setState({
            messages
        })
    }

    goToMessage = (id) => {
        document.getElementById('chat-body').scrollTop = document.getElementById(id).offsetTop - 200
        $("#" + id).addClass('highlight')
    }

    onAddMod = () => {
        if (!this.state.addMod) {
            this.setState({ 'addMod': !this.state.addMod });
        }
        else {
            if (this.state.mod_id != false) {
                if (confirm('Bạn có chắc chắn không?')) {
                    this.setState({ 'addMod': !this.state.addMod });
                    this.props.addModToConversation({
                        id: this.props.currentConversation.id,
                        mod_id: this.state.mod_id
                    })
                }
            }

            else alert('Chọn Mod');
        }

    }

    onRemoveMod = (uid) => {
        if (confirm('Bạn có chắc chắn không?'))
            this.props.getConversationModRemove(this.props.currentConversation.id, uid)
    }

    render() {
        const { mods, currentConversation } = this.props;
        if (!currentConversation.user) {
            return <div />
        }
        const user = currentConversation.user
        let currentChild = null;
        if (currentConversation.type == 'session') {
            currentChild = user.children && user.children.find(item => item.id == currentConversation.child_id);
        }


        return (
            <div className="sidebar-group mobile-open">
                <div id="contact-information" className="sidebar active">
                    <header>
                        <span>Profile</span>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a href="#" className="btn btn-outline-light text-danger sidebar-close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </a>
                            </li>
                        </ul>
                    </header>
                    <div className="sidebar-body" tabIndex="6" style={{ overflow: 'auto', outline: 'none' }}>
                        <div className="text-center">
                            <Avatar user={currentConversation.user} size="medium" className="avatar avatar-xl" />
                            <h5 className="mb-1">{user?.name ?? '...'}</h5>
                            <ul className="nav nav-tabs justify-content-center mt-2" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="false">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="note-tab" data-toggle="tab" href="#note" role="tab" aria-controls="note" aria-selected="false">Note {currentConversation.note && <sup className='badge badge-danger badge-pill p-1'> </sup>}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="remind-tab" data-toggle="tab" href="#remind" role="tab" aria-controls="remind" aria-selected="true">Tin nhắn {(currentConversation.conversation_remind?.length > 0) && <sup className='badge badge-danger badge-pill p-1'> </sup>}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="mod-tab" data-toggle="tab" href="#mod" role="tab" aria-controls="media" aria-selected="false">Mods</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="search-tab" data-toggle="tab" href="#search" role="tab" aria-controls="search" aria-selected="false">Search</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" id="order-tab" data-toggle="tab" href="#order" role="tab" aria-controls="order" aria-selected="false">Order</a>
                                </li>
                            </ul>
                        </div>
                        <div className="pl-2 pr-2">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade" id="search" role="tabpanel" aria-labelledby="search-tab">
                                    <div className="input-group mt-3">
                                        <input type="text" className="form-control" placeholder="Tìm kiếm" onChange={ref => this.setState({ search: ref.target.value })} />
                                        <button className="btn btn-primary ml-3" type="button" onClick={this.onSearchMessage}>Tìm kiếm</button>
                                    </div>
                                    <table className="table table-striped table-bordered mt-3">
                                        <tbody>
                                            {this.state.messages && this.state.messages.length > 0 && this.state.messages.map(message =>
                                                <tr>
                                                    <td>
                                                        <p>{message.created_at}</p>
                                                        <p>{message.content}</p>
                                                        <a href="#" onClick={() => this.goToMessage(message.id)}>View Message</a>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id="note" role="tabpanel" aria-labelledby="note-tab">
                                    <TextareaAutosize className="form-control" rows="5" value={this.state.note} onChange={ref => this.setState({ note: ref.target.value })}></TextareaAutosize>
                                    <button className="btn btn-primary mt-3" onClick={this.onSaveNote}>Save</button>
                                </div>
                                <div className="tab-pane fade" id="remind" role="tabpanel" aria-labelledby="remind-tab">
                                    <ConversationRemindComponent />
                                </div>
                                <div className="tab-pane fade" id="mod" role="tabpanel" aria-labelledby="mod-tab">
                                    <ModsList users={currentConversation.users} isAllowToRemoveMod={this.props.isAllowToRemoveMod} onRemoveMod={this.onRemoveMod} />

                                    {this.props.isAllowToAddMod && <div className="row mt-3">
                                        <div className="col-md-8">
                                            {this.state.addMod && <select className="form-control" onChange={ref => this.setState({ mod_id: ref.target.value })}>
                                                <option value={false}>Chọn Mod</option>
                                                {this.props.filterMods().map((mod, key) =>
                                                    <option key={key} value={mod.id}>{mod.id} - {mod.name}</option>
                                                )}
                                            </select>}</div>
                                        <div className="col-md-4"><button className="btn btn-primary" onClick={this.onAddMod}>Thêm</button></div>
                                    </div>}


                                </div>
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                    <UserInfo user={user} />

                                    <fieldset className="mt-3">
                                        <h6><strong>Thông tin bé</strong></h6>
                                        <ChildrenInfo child_id={currentConversation.child_id} children={user.children} />
                                    </fieldset>
                                    {currentConversation && currentConversation.type == 'session' && currentChild && <fieldset className="mt-3">
                                        <h6><strong>Lịch sinh hoạt</strong></h6>
                                        <ChildSchedule schedules={currentChild.schedules} />
                                    </fieldset>}
                                    <fieldset className="mt-3">
                                        <h6><strong>Thiết bị</strong></h6>
                                        <DeviceInfo devices={user.devices} />
                                    </fieldset>
                                </div>
                                <div className="tab-pane fade show" id="order" role="tabpanel" aria-labelledby="order-tab">
                                    <fieldset className="mt-3">
                                        <h6><strong>Khóa học đã mua</strong></h6>
                                        <UserOrders orders={user.orders} />
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentConversation: state.conversation.currentConversation,
    // user: state.conversation.user,
});
const mapDispatchToProps = {
    addModToConversation,
    getConversationModRemove,
    // getUserInfo,
    // getConversationDetail,
    saveNoteToConversation
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);