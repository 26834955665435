import {
  GET_CONVERSATIONS_COURSES,
  SET_CONVERSATION_CURRENT, GET_CONVERSATIONS, GET_CONVERSATION_DETAIL, UPDATE_CONVERSATION,
  SET_CONVERSATION_ADD_MOD, GET_MODS, GET_CONVERSATION_MOD_REMOVE,
  SEND_MESSAGE, SEND_VIDEO, SEND_IMAGE, RECEIVED_MESSAGE, ON_READ,
  GET_USER_INFO,
  POST_CREATE_CONVERSATION_REMIND, DEL_CONVERSATION_REMIND, SET_CONVERSATION_NOTE
} from '../';

export const saveNoteToConversation = (params) => ({
  type: SET_CONVERSATION_NOTE.REQUEST,
  params,
});
export const addModToConversation = (params, callback) => ({
  type: SET_CONVERSATION_ADD_MOD.REQUEST,
  params,
  callback
});

export const setCurrentConversation = (conversation) => ({
  type: SET_CONVERSATION_CURRENT.SUCCESS,
  conversation
});

export const getConversationModRemove = (id, mod_id) => ({
  type: GET_CONVERSATION_MOD_REMOVE.REQUEST,
  id,
  mod_id
});

export const getConversations = (mode, options = {}) => ({
  type: GET_CONVERSATIONS.REQUEST,
  mode,
  options
});

export const getModsList = () => ({
  type: GET_MODS.REQUEST
});

export const getCoursesList = () => ({
  type: GET_CONVERSATIONS_COURSES.REQUEST
});

export const sendMessage = (id, child_id, content) => ({ type: SEND_MESSAGE.REQUEST, id, child_id, content });
export const receivedMessage = messageId => ({
  type: RECEIVED_MESSAGE.REQUEST,
  messageId
});
export const sendImage = (filename, image, id) => ({
  type: SEND_IMAGE.REQUEST,
  filename, image, id
});

export const sendVideo = (filename, video, id) => ({
  type: SEND_VIDEO.REQUEST,
  filename, video, id
});

export const getUserInfo = (id, params) => ({ type: GET_USER_INFO.REQUEST, id, params });

export const updateConversation = (id, params) => ({
  type: UPDATE_CONVERSATION.REQUEST,
  id,
  params
});
export const onRead = id => ({
  type: ON_READ.REQUEST,
  id
});


export const getConversationDetail = id => ({
  type: GET_CONVERSATION_DETAIL.REQUEST,
  id
});
export const createConversationRemind = (data, callback) => ({
  type: POST_CREATE_CONVERSATION_REMIND.REQUEST,
  data: data,
  callback
});
export const delConversationRemind = (conversation_id, id, callback) => ({
  type: DEL_CONVERSATION_REMIND.REQUEST,
  conversation_id,
  id,
  callback
});