import moment from "moment";

import { Configs } from '.';

// import('moment/locale/' + Configs.locale);
moment.updateLocale('vi', {
    relativeTime: {
        future: '%s tới',
        past: '%s trước',
        s: 'vài giây',
        ss: '%d giây',
        m: '1 phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        w: '1 tuần',
        ww: '%d tuần',
        M: '1 tháng',
        MM: '%d tháng',
        y: '1 năm',
        yy: '%d năm',
    },
});

moment.locale('vi');
// console.log(moment.locales())
// console.log(moment.months())
export default moment;
// export { moment };