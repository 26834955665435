const MEDIA_TYPE_VIDEO = [
    'mp4', 'wmv', 'mpeg', 'webm', 'mov', 'qt'
]

export default {
    isVideo(url) {
        let path = url.split('.');
        if (MEDIA_TYPE_VIDEO.indexOf(path[path.length-1].toLowerCase()) !== -1)
            return true
        return false;
    },
};
