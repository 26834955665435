import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from '../configs/moment';

import { getConversations, setCurrentConversation, sendMessage, getCoursesList, getModsList } from '../redux/actions/action_conversation';

import { firebase, onListenFirestore } from '../helpers/firebase';

const debug = require('debug')('conversation:list')

import { ConversationListingItem } from './conversation'


class ConversationListComponent extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore().collection('conversations')
        this.state = {
            checkpoint: moment().format('YYYY-MM-DD HH:mm:ss'), //'2021-06-03 09:00:00'
        };
    }

    componentDidMount() {
        // this.props.onSearch();

        this.subscribe();

    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    subscribe = () => {
        try {

            let collection = this.db
                // .where('updated_at', '>=', this.state.checkpoint)
                .orderBy('updated_at', 'desc')
                .limit(1)
            debug('Firebase', 'listening event at [', this.db.path, ']', this.state.checkpoint);
            this._unsubscribe = onListenFirestore(collection, (OnSnapshot) => {
            }, (onChange) => {
                {
                    this.props.onSearch('auto', { page: 1 })
                    // const docData = onChange.doc.data()
                    // // debug(docData)
                    debug('Firebase', onChange.type, onChange.doc.ref.path)
                    // if (onChange.type === 'modified') {
                    //     // this.onSearch()
                    // }
                    // if (onChange.type === 'added') {
                    //     this.props.onSearch('auto', { page: 1 })
                    // }
                    // if (onChange.type === 'removed') {
                    //     // this.onSearch()
                    // }
                }
            })
        }
        catch (ex) {
            console.error('Firebase', ex);
        }
    };

    unsubscribe = () => {
        if (typeof this._unsubscribe === 'function') {
            this._unsubscribe();
            this._unsubscribe = null;
        }
    };
    sortConv(conversations) {
        // return [conversations,];
        //Sắp xếp theo time của thời gian đợi
        let ModChuaXem = conversations.filter(item => item.mess_after_mod > 0).sort((a, b) => {
            return moment(a.wait_from_time).format("X") - moment(b.wait_from_time).format("X")
        })
        //Sắp xếp theo time của tin nhắn cuối cùng (hoặc updated_at)
        let ModDaXem = conversations.filter(item => (item.mess_after_mod == undefined || item.mess_after_mod <= 0)).sort((a, b) => {
            return moment(b.updated_at).format("X") - moment(a.updated_at).format("X")
        })
        return [ModChuaXem, ModDaXem];

    }

    onSelectConversation = (conversation, index) => {
        this.props.setCurrentConversation(conversation);
    }

    isHighLight(conversation) {
        return conversation.mess_after_mod > 0 ? true : false
    }

    render = () => {
        const { conversations, myList, current, currentConversation } = this.props;
        let total_unread = conversations.filter(item => this.isHighLight(item)).length;

        const conversations_leveled = conversations.filter(item => item.conversation_level == 'premium');

        let leveled_unread = conversations_leveled.filter(item => this.isHighLight(item)).length;

        let MyList_unread = myList?.filter(item => this.isHighLight(item)).length;

        return [<ul className="nav nav-tabs" key="tab" id="conversation" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#conversation-list" role="tab" aria-controls="home" aria-selected="true">Tất cả {total_unread ? <sup className="badge badge-danger badge-pill p-1"> </sup> : ''}</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#conversation-leveled" role="tab" aria-controls="note" aria-selected="false">Ưu tiên {leveled_unread ? <sup className="badge badge-danger badge-pill p-1"> </sup> : ''}</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#conversation-recent" role="tab" aria-controls="note" aria-selected="false">Gần đây {MyList_unread ? <sup className="badge badge-danger badge-pill p-1"> </sup> : ''}</a>
            </li>
        </ul>,
        <div className="sidebar-body" tabIndex="2" style={{ overflow: 'auto', outline: 'none' }} key="listing">
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane active" id="conversation-list" role="tabpanel" aria-labelledby="search-tab">
                    {/* <div className="col-md-12">Hiển thị {conversations.length} / tổng số {current.total}</div> */}
                    <ul className="list-group list-group-flush">
                        {this.sortConv(conversations).map(group => group.map((conversation, index) => <ConversationListingItem key={conversation.id} conversation={conversation} onClick={this.onSelectConversation} currentConversation={currentConversation} />))}
                    </ul>
                    {current && current.current_page != current.last_page &&
                        <div className="text-center mt-1 mb-1"><button
                            className="btn btn-sm btn-info"
                            onClick={() => this.props.onLoadMore(current.current_page + 1)}
                        >Tải thêm cuộc hội thoại</button></div> || <div className="text-center mt-3 text-muted">Đã hết dữ liệu</div>}
                </div>
                <div className="tab-pane" id="conversation-leveled" role="tabpanel" aria-labelledby="search-tab">
                    <ul className="list-group list-group-flush">
                        <ul className="list-group list-group-flush">
                            {conversations_leveled.map((conversation, key) => <ConversationListingItem key={conversation.id} conversation={conversation} onClick={this.onSelectConversation} currentConversation={currentConversation} />)}
                        </ul>
                    </ul>
                </div>
                <div className="tab-pane" id="conversation-recent" role="tabpanel" aria-labelledby="search-tab">
                    <div className="text-warning small text-center">Lưu trên máy tính bạn đang dùng</div>
                    <ul className="list-group list-group-flush">
                        {myList?.map((conversation, index) => <ConversationListingItem key={conversation.id} conversation={conversation} onClick={this.onSelectConversation} currentConversation={currentConversation} />)}
                    </ul>
                </div>
            </div>
        </div>]

    }
}

const mapStateToProps = state => ({
    // user: state.login.user,
    // // isFetching: state.isFetching,
    conversations: state.conversation.conversations,
    myList: state.conversation.myList,
    // // isUpdatingConversation: state.conversation.isUpdatingConversation,
    currentConversation: state.conversation.currentConversation,
    // mods: state.conversation.mods,
    // courses: state.conversation.courses,
    current: state.conversation.current
});
const mapDispatchToProps = {
    setCurrentConversation,
    // getConversations,
    // getModsList,
    // getCoursesList,
    // sendMessage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConversationListComponent);