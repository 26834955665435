import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger'
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import localforage from 'localforage';
import createSagaMiddleware from 'redux-saga'
import sagas from './redux/sagas';
import reducers from './redux/reducers';
import axios from 'axios';
import Home from './containers/Home';
import Drawer from './containers/Drawer';
import Login from './containers/Auth/Login';
import Conversation from './containers/Conversation/index';
import ErrorBoundary from './ErrorBoundary';

import LoadingComponent from './components/loading';

const logger = createLogger({
  collapsed: true,
  // logger: console
});

const defaultFontFamily = [
  'Nunito Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')


let rehydrationComplete;
const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
});
const persistConfig = {
  key: 'root',
  storage: localforage,
}
const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()
const store = compose(applyMiddleware(logger, sagaMiddleware))(
  createStore
)(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
);


sagaMiddleware.run(sagas)


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rehydrate: false
    }

    this.persistor = persistStore(store, {}, () => {
      rehydrationComplete();
      const { token } = store.getState().login;
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
      this.setState({ rehydrated: true });
    });
  }

  render() {
    if (!this.state.rehydrated) {
      return <LoadingComponent />;
    }
    return (
      <ErrorBoundary>
        <PersistGate loading={null} persistor={this.persistor}>
          <Router>
            <Provider store={store}>
              <Switch>
                <Route exact path="/login" component={Login} />

                <Drawer>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/conversation" component={Conversation} />
                    {/* <Route exact path="/support" component={Support} /> */}
                  </Switch>
                </Drawer>
              </Switch>
            </Provider>
          </Router>
        </PersistGate>
      </ErrorBoundary>
    );
  }
}

export default App;
