import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import { firebase, onListenFirestore } from '../../helpers/firebase';
import { getUserInfo, sendMessage, sendImage, sendVideo, receivedMessage, getConversationDetail, onRead, updateConversation, addModToConversation } from '../../redux/actions/action_conversation';
import { CDN_URL, API_ROOT } from '../../configs/API';
// import Utils from '../../utils';
// import helper from '../../helpers';
const debug = require('debug')('conversation:detail')
// const debug = console.log;
import { MessageInputForm, MessageListing, ConversationHeader } from '../../components/conversation'

import AvatarComponent from '../../components/avatar'
import MessageItemComponent from '../../components/message-item'
import moment from '../../configs/moment';

class ConversationDetail extends Component {

  constructor(props) {
    super(props);
    this.db = false;
    this.state = {
      goBottom: true,
      isBottom: true,
      newMsg: 0,
      loadmore: false,
      limit: 10,
      messages: [],
      content: ''
    };
    this.viewport = null
    this.onSend = this.onSend.bind(this);
  }

  componentDidMount() {
    const { currentConversation } = this.props;
    if (currentConversation) {
      this.props.getUserInfo(currentConversation.user_id, this.props.buildQueryParams());
      this.props.getConversationDetail(currentConversation.id)

    }

    this.setState({
      messages: []
    }, () => {
      //  this.props.getConversationDetail(this.props.conversation.id)
      this.onLoadMessages();
    })
    this.viewport = document.getElementById("chat-body");
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentConversation } = this.props;

    if (prevProps.currentConversation.id != currentConversation.id) {
      this.props.getUserInfo(currentConversation.user_id, this.props.buildQueryParams());
      this.props.getConversationDetail(currentConversation.id)
    }

    if (prevProps.currentConversation && currentConversation && prevProps.currentConversation.id !== currentConversation.id) {
      this.firestoreUnlistening()
      this.setState({
        // goBottom: true,
        loadmore: true,
        messages: [],
        content: ''
      }, () => {
        this.onLoadMessages();
      })

    }
  }
  componentWillUnmount() {
    this.firestoreUnlistening()
  }

  firestoreUnlistening = () => {
    // console.log(this.observer);
    if (this.observer)
      this.observer();
  }

  onLoadMessages() {
    // return;
    const { currentConversation } = this.props;
    if (!currentConversation)
      return;
    this.db = firebase.firestore().collection(currentConversation.firestore_id + '/messages');

    this.onListenRealtime()
  }

  onListenRealtime() {
    const timestamp = moment().unix();
    let lastDoc = false;
    let doc = this.db
      // .where('timestamp', '>', timestamp)
      .orderBy('timestamp', 'desc')
      // .orderBy('updated_at', 'desc')
      // .startAfter(lastDoc)
      .limit(this.state.limit)
    debug('Firebase', 'Listen for changes [' + this.db.path + '] ');

    this.observer = onListenFirestore(doc, (snapshot) => {
      debug('Firebase', 'Received snapshot', snapshot.size)
      if (snapshot.size > 0)
        this.setState({ loadmore: true })
    }, (onChange) => {
      {
        let messages = [...this.state.messages]
        debug('Firebase', onChange.type, onChange.doc.id)
        if (onChange.type === 'added') {
          var message = { ...onChange.doc.data(), id: onChange.doc.id, path: onChange.doc.ref.path };
          if (!message.received) {
            // console.log(message.messageId)
            // this.props.receivedMessage(message.messageId)
          }
          messages.push(message)
          this.state.newMsg += 1
        }

        if (onChange.type === 'removed') {
          messages = messages.filter(item => item.id !== onChange.doc.id)
        }

        if (onChange.type === 'modified') {
          messages = messages.map(item => {
            if (item.id == onChange.doc.id)
              item = { ...onChange.doc.data(), id: onChange.doc.id, path: onChange.doc.ref.path };
            return item
          })
        }

        this.setState({
          messages: this.sortMessages(messages),
          newMsg: this.state.isBottom ? 0 : this.state.newMsg
        })
        if (this.state.isBottom)
          this.scrollToBottom()
      }
    })
  }

  onLoadOldMessate() {
    // if (!this.startBefore) return
    this.db.orderBy('timestamp', 'desc').where('timestamp', '<', this.state.messages[0].timestamp).limit(this.state.limit).get().then(snapshot => {
      snapshot.forEach(doc => {
        this.startBefore = doc;
        // console.log(doc.data());
        this.state.messages.unshift({ id: doc.id, path: doc.ref.path, ...doc.data() })
      })
      if (snapshot.size < this.state.limit)
        this.state.loadmore = false
      this.setState({
        goBottom: false,
        loadmore: this.state.loadmore,
        messages: this.state.messages
      })
      this.onScroll()
    });
  }

  onSend(e) {
    e.preventDefault()
    const { currentConversation, user } = this.props;
    if (this.state.content) {
      this.props.sendMessage(currentConversation.id, currentConversation.child_id, this.state.content);
      this.setState({
        content: ''
      })
      // this.props.getConversationDetail(this.props.conversation.id)
    }
  }

  onChangeImage = (image) => {
    const file = image.files[0];
    const { id } = this.props.currentConversation;
    // console.log('abc', id, file); return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (reader.result.indexOf("data:video") != -1) {
        if ((file.size / 1024 / 1024) > 50) {
          alert('Max size of video is 50MB');
          return
        }
        this.props.sendVideo(
          "video.mp4",
          file,
          id
        );
      }

      let image = new Image();
      image.src = reader.result;
      image.onload = () => {

        if ((file.size / 1024 / 1024) > 5) {
          alert('Max size of image is 5MB');
        }
        else {
          const { id } = this.props.currentConversation;

          this.props.sendImage(
            "test.png",
            file,
            id
          );
        }
      }
    }, false);

    if (typeof file !== 'undefined') {
      reader.readAsDataURL(file);
    }

  }


  renderStatus = (conversation) => {

    if (conversation.type == 'session') {
      return 'Tư vấn';
    }
    if (conversation.type == 'poh') {
      return 'Chat với POH';
    }
    if (conversation.type == 'session') {
      return 'Tư vấn mua khóa học';
    }
  }

  onComplete = () => {
    if (confirm("Bạn có chắc chắn muốn hoàn thành cuộc hội thoại này?")) {
      this.props.updateConversation(this.props.currentConversation.id)
    }
  }

  onRead = () => {
    this.props.onRead(this.props.currentConversation.id)
  }

  onDeleteMessage = (message) => {
    // console.log(message)
    if (confirm("Bạn có chắc chắn muốn xóa tin nhắn này?")) {
      firebase.firestore().doc(this.props.currentConversation.firestore_id + '/messages/' + message.id).delete().then(() => {
        let messages = [...this.state.messages]
        messages = messages.filter(item => item.id != message.id)
        this.setState({ messages: messages });
      });
    }
  }

  _handleKeyDown = (e) => {
    e.persist();


    if (e.key === 'Enter' && !e.shiftKey == true) {
      this.onSend(e)
    }
    if (e.key === 'Enter' && e.shiftKey == true) {
      // e.preventDefault()
      // console.log(e); 
    }
  }


  isCurrentConversationOpen = () => {
    return this.props.currentConversation && this.props.currentConversation.status != 'close'
  }

  updateScroll(goBottom = false) {

    if (goBottom) {
      var height = this.viewport.scrollHeight;
    } else {
      var height = 0
    }
    this.viewport.scrollTop = height;
    debug('scroll', goBottom, 'messages.height: ', messages.scrollHeight, 'chat-body.scrollTop:', this.viewport.scrollTop)
  }

  scrollToBottom = () => {
    this.viewport.scrollTop = this.viewport.scrollHeight;
    this.setState({ newMsg: 0 })
    debug('scroll to bottom')
  }

  onScroll = () => {
    const isBottom = this.viewport.scrollHeight - this.viewport.scrollTop === this.viewport.clientHeight;

    // debug(`onScroll, clientHeight: ${this.viewport.clientHeight}, scrollTop: ${this.viewport.scrollTop}, height:  ${this.viewport.scrollHeight}`, isBottom)
    this.setState({
      isBottom: isBottom,
      newMsg: isBottom ? 0 : this.state.newMsg
    })
  }

  sortMessages = (messages) => {
    return messages.sort((a, b) => {
      return a.timestamp - b.timestamp
    })
  }
  onAddSelf = () => {
    if (confirm('Bạn có chắc chắn muốn tham gia không?')) {
      this.props.addModToConversation({
        id: this.props.currentConversation.id,
        mod_id: this.props.user.id
      })
    }
  }


  render() {
    const { user, currentConversation } = this.props;
    // console.log(currentConversation);
    if (!currentConversation) {
      return <div>Chưa nó nội dung trao đổi</div>;
    }

    // console.log(this.state.messages)
    return (
      <div id="chat" className="chat">
        <div className="chat-header">
          <ConversationHeader currentConversation={currentConversation} isAllowToReply={this.props.isAllowToReply} onRead={this.onRead} renderStatus={this.renderStatus} />
        </div>
        {this.state.showRemind && <ConversationRemindComponent onClose={() => { this.setState({ showRemind: false }) }} />}
        <div id="chat-body" className="chat-body" tabIndex="1" onScroll={this.onScroll}>
          {this.state.loadmore &&
            <div className="text-center">
              <button className="btn btn-sm btn-info" onClick={this.onLoadOldMessate.bind(this)}>
                Nội dung cũ hơn
              </button></div>}
          <MessageListing id={"messages"} messages={this.state.messages} currentUser={user} currentConversation={this.props.currentConversation} onDeleteMessage={this.onDeleteMessage} />
        </div>
        <div>
          {(!this.state.isBottom && (this.state.newMsg > 0)) && <div className="text-center" style={{ position: 'relative' }}><button className="btn btn-sm btn-info" onClick={this.scrollToBottom}><i className="las la-angle-double-down"></i> <span className="ml-1 mr-1">{this.state.newMsg} Tin nhắn mới</span> <i class="las la-angle-double-down"></i></button></div>}
          <div className="chat-footer">
            {this.isCurrentConversationOpen() ?
              (this.props.isAllowToReply ?
                // this.renderInputForm()
                < MessageInputForm onKeyPress={this._handleKeyDown} content={this.state.content} onChange={(ref) => this.setState({ content: ref.target.value })} onChangeImage={this.onChangeImage} onSend={this.onSend} />
                : <div className="text-warning text-center">
                  <h4>Bạn chưa được tham gia thảo luận này.</h4>
                  {this.props.isAllowToSelfAdd && <div><button className="btn btn-success" onClick={this.onAddSelf}>Thêm tôi vào</button></div>}
                </div>) :
              <h4 className="text-success text-center">Cuộc hội thoại này hiện đã Đóng</h4>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  currentConversation: state.conversation.currentConversation
});
const mapDispatchToProps = {
  addModToConversation,
  sendMessage,
  sendImage,
  receivedMessage,
  getConversationDetail,
  getUserInfo,
  onRead,
  updateConversation,
  sendVideo
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationDetail);