import { Configs } from '../configs';

import moment from "../configs/moment";
export default {
    formatNumberThousands: (x, commas = '.') => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, commas);
    },
    formatDateFromNow: (date, ifEmpty) => {
        return date ? moment(date).fromNow() : ifEmpty;
    },
    formatDate: (date, ifEmpty = '-') => {
        return date ? moment(date).format(Configs.date.normal) : ifEmpty;
    },
    formatDateShort: (date, ifEmpty = '-') => {
        return date ? moment(date).format('DD/MM/YY') : ifEmpty;
    },
    formatDateTimeShort: (date, ifEmpty = '-') => {
        return date ? moment(date).format('DD/MM HH:mm') : ifEmpty;
    },
    formatDateFull: (date, ifEmpty = '-') => {
        return date ? moment(date).format(Configs.date.full) : ifEmpty;
    },
    formatHourFull: (date, ifEmpty = '-') => {
        return date ? moment(date).format(Configs.hour.full) : ifEmpty;
    },
    formatHourShort: (date, ifEmpty = '-') => {
        return date ? moment(date).format("HH:mm") : ifEmpty;
    },
    hasPermission: (key, permissions = []) => {
        // console.log(permissions)
        return permissions.includes(key)
    }
}
