import React from 'react';
import { Card, Accordion } from 'react-bootstrap'

import Utils from '../utils';
import moment from '../configs/moment';

const UserInfo = (props) => <div>
    <div className="mt-2">
        <h6>Tên mẹ: {props.user.name}</h6>
    </div>
    <div className="mt-2">
        <h6>Tên bố: {props.user.father}</h6>
    </div>
    <div className="mt-2">
        <h6>Email: {props.user.email}</h6>
    </div>
    <div className="mt-2">
        <h6>Phone: {props.user.phone}</h6>
    </div>
    <div className="mt-2">
        <h6>Ngày tạo: {Utils.formatDateFull(props.user.created_at)}</h6>
    </div>
</div>

const ChildSchedule = props => <Accordion defaultActiveKey={0}>
    {props.schedules && props.schedules.map((schedule, index) => <Card key={index}>
        <Accordion.Toggle as={Card.Header} eventKey={index.toString()} style={{ cursor: "pointer" }}>
            {Utils.formatDateShort(schedule.date)}
            <div className='float-right'>
                <span className='badge badge-pill badge-primary p-1 m-1'>WT: {schedule.summary.wt.text}</span>
                <span className='badge badge-pill badge-info p-1 m-1'>ST: {schedule.summary.st.text}</span>
                <span className='badge badge-success p-1 m-1'>{Utils.formatNumberThousands(schedule.summary.eat_in_mil)}ml</span></div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={index.toString()} className="m-2">
            <div>
                <div>Lịch: <b>{schedule.schedule?.name}</b></div>
                {/* <div>Tổng giờ thức: <strong>{schedule.summary.wt.text}</strong></div>
                <div>Tổng giờ ngủ: <strong>{schedule.summary.st.text}</strong></div>
                <div>Tổng lượng ăn: <strong>{Utils.formatNumberThousands(schedule.summary.eat_in_mil)}ml</strong></div> */}
                {schedule.sections.length > 0 && <table className="table table-striped table-sm">
                    <tbody>
                        {schedule.sections && schedule.sections.map((section, key) =>
                            [<tr key={key}>
                                <td>
                                    <strong>{section.name}</strong>
                                </td>
                                <td>{section.time}</td>
                                <td>{section.duration_in_text}</td>
                                <td>{section.eat_in_mil ? Utils.formatNumberThousands(section.eat_in_mil) + 'ml' : ''}</td>
                            </tr>,
                            section.note && <tr key={key + 'note'}>
                                <td colSpan={5} className="text-muted font-italic">{section.note}</td>
                            </tr>]
                        )}
                        {(schedule.summary.midnight.eat_in_mil || schedule.summary.midnight.note) && [<tr key="midnight">
                            <td>
                                <strong>Ngủ đêm</strong>
                            </td>
                            <td>{schedule.summary.midnight.duration_in_text}</td>
                            <td>{schedule.summary.midnight.eat_in_mil ? schedule.summary.midnight.eat_in_mil + 'ml' : ''}</td>
                        </tr>,
                        <tr key={'midnight-note'}>
                            <td colSpan={5} className="text-muted">{schedule.summary.midnight.note}</td>
                        </tr>]}
                    </tbody>
                </table>}
            </div>
        </Accordion.Collapse>
    </Card>)}
</Accordion>

const DeviceInfo = props => props.devices && props.devices.map((device, key) => <div className="media mt-1" key={key}>
    <img src={"images/icon_" + device.platform + ".png"} className="rounded-circle pr-1" alt="image" />
    <div className="pull-right small">{device.user_agent}</div>
</div>) || null;

const UserOrders = props => props.orders && props.orders.map((item, key) => <div className="mt-1" key={key}>
    <div className={(item.status == 'active') ? 'text-success' : 'text-warning'}>{item.short_name}</div>
    <ul>
        <li><b>{item.child_name}</b> - {item.birth_string}</li>
        <li>Ngày mua: {Utils.formatHourFull(item.created_at)}</li>
        {/* <li>Ngày hết hạn: {Utils.formatHourFull(item.end_at)}</li> */}
    </ul>
</div>) || null;

const ChildInfo = props => <div>
    <strong>- {props.child.name}</strong>
    <div className='pl-2'>
        <div>Thực sinh: {props.child.birthday_obj ? props.child.birthday_obj.birth_string : props.child.birth_string}</div>
        <div>Dự sinh: {props.child.due_date_obj?.birth_string ?? '-'}</div>
        <blockquote className='text-muted'>{props.child.notes}</blockquote>
    </div>
</div>

const ChildrenInfo = props => {
    if (props.children) {
        if (props.child_id != null)
            return props.children.filter(item => item.id == props.child_id).map((child, key) => <ChildInfo key={key} child={child} />)

        return props.children.map((child, key) => <ChildInfo key={key} child={child} />)
    }
    return null;
}

const ModsList = ({ users, isAllowToRemoveMod, onRemoveMod }) => {
    return users?.filter(item => item.role == 'mod').map((u, key) => <div key={key} className="mt-1">
        {isAllowToRemoveMod && <div className='float-right my-2'><button className="btn btn-sm btn-danger" onClick={() => onRemoveMod(u.user_id)}>X</button></div>}
        <strong>{u.name}</strong> ({u.user_id})<br />Joined: <b>{moment(u.created_at).format("DD/MM - HH:mm")}</b> (tác nhân: {u.created_by})
    </div>) ?? 'Chưa có mod'
}

export { UserInfo, ChildSchedule, DeviceInfo, UserOrders, ChildInfo, ChildrenInfo, ModsList }