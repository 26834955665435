import React, { Component } from 'react';
import { connect } from 'react-redux';

import Utils from '../utils';
import API from '../configs/API';
// import { firebase, onListenFirestore } from '../../helpers/firebase';
import { createConversationRemind, delConversationRemind } from '../redux/actions/action_conversation';
import moment from '../configs/moment';
import debug from 'debug';
// import { CDN_URL, API_ROOT } from '../../configs/API';
// import helper from '../../helpers';
// import TextareaAutosize from 'react-textarea-autosize';
// const debug = require('debug')('conversation:detail')

class ConversationRemindComponent extends Component {

    constructor(props) {
        super(props);
        this.empty = {
            id: null,
            last_message_date_diff: null,
            message: '',
            send_at: ''
        },
            this.check_type_days_type = {
                'gestational': -7,
                'week': 7,
                'month': 30,
                'year': 360,
            }

        this.state = {
            id: null,
            type: 'date',
            check_days_value: 0,
            check_days_type: 'gestational',
            days: null,
            last_message_date_diff: null,
            showForm: false,
            message: '',
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
    }

    onSubmitRemind = (event) => {
        event.preventDefault()
        this.props.createConversationRemind({
            id: this.state.id ?? null,
            message: this.state.message,
            send_at: this.state.send_at ? moment(this.state.send_at).format('YYYY-MM-DD HH:mm:ss') : null,
            days: this.state.days,
            type: this.state.type,
            check_days_value: this.state.check_days_value,
            check_days_type: this.state.check_days_type,
            last_message_date_diff: this.state.last_message_date_diff,
            conversation_id: this.props.currentConversation.id,
        }, () => {
            this.setState({ ...this.empty, showForm: false });
        });
    }

    _updateDays() {
        let days = this.state.check_days_value * this.check_type_days_type[this.state.check_days_type]
        if (days <= 0 && this.state.check_days_type == 'gestational') {
            days = -280 - days
        }
        this.setState({ days: days })
    }

    getSendAtText = (item) => {
        if (item.type == 'days') {
            return item.days + ' ngày tuổi'
        }

        return Utils.formatDateTimeShort(item.send_at);
    }

    onEdit = (item) => {
        this.setState({ ...item, showForm: true })
    }

    closeForm = () => {
        this.setState({ ...this.empty, 'showForm': false })
    }

    onDelete = () => {
        if (!confirm('Chắc chắn không?')) return;
        this.props.delConversationRemind(this.props.currentConversation.id, this.state.id, () => {
            this.setState({ ...this.empty, 'showForm': false })
        });

    }
    onAdd = () => {
        this.setState({ ...this.empty, showForm: true });
    }

    render() {
        return <div className="mt-2">
            <div className="mb-2">Tự gửi tin nhắn vào cuộc hội thoại này vào thời điểm định sẵn</div>
            <div className="text-right mb-2">{this.state.id != undefined && <button type="button" className="btn btn-danger" onClick={this.onDelete}>Xóa</button>}</div>
            {this.state.showForm && <form onSubmit={this.onSubmitRemind} method="post">
                <div className=' form-group'>
                    <select className='form-control' onChange={(ref) => this.setState({ type: ref.target.value })}>
                        <option value={'date'}>Gửi vào ngày cụ thể</option>
                        <option value={'days'}>Gửi theo tuổi thai</option>
                    </select>
                </div>
                {this.state.type != 'days' && <div className='form-group'>
                    <div><input type="datetime-local" className="form-control"
                        min={moment().format("YYYY-MM-DD HH:mm")}
                        required
                        value={moment(this.state.send_at).format('YYYY-MM-DDTHH:mm')} onChange={(ref) => this.setState({ send_at: ref.target.value })}
                    /></div>
                    <div className='form-text text-muted'>Vào thời điểm này tin nhắn sẽ được gửi đến <b>{this.props.currentConversation.user.name}</b></div>
                </div>}

                {this.state.type == 'days' && <div className='row form-group'>
                    <div className='col-4'><input className='form-control' type="number" value={this.state.check_days_value ?? ""} min={0} onChange={(ref) => this.setState({ check_days_value: parseInt(ref.target.value != '' ? ref.target.value : 0) }, () => this._updateDays())} /></div>
                    <div className='col-8'> <select className='form-control' onChange={(ref) => this.setState({ check_days_type: ref.target.value }, () => this._updateDays())}>
                        <option value={'gestational'}>Tuần thai</option>
                        <option value={'week'}>Tuần tuổi</option>
                        <option value={'month'}>Tháng tuổi</option>
                        <option value={'year'}>Năm tuổi</option>
                    </select></div>
                    <div className='col form-text text-muted'>{this.state.days != null ? `${this.state.days} ngày tuổi.` : ''}</div>
                </div>}



                <div className="form-group">
                    <label>Không gửi nếu có tin nhắn mới trong thời gian</label>
                    <div className="input-group">
                        <input type="number" className="form-control" style={{ "maxWidth": "70px" }} min={0} aria-describedby="basic-addon2"
                            value={this.state.last_message_date_diff ?? ''} onChange={(ref) => this.setState({ last_message_date_diff: ref.target.value })}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">Ngày</span>
                        </div>
                    </div>
                    <small className="form-text text-muted">Không phân biệt tin nhắn từ Khách hay từ các Mods. Để trống hoặc nhập 0 để luôn gửi</small>
                </div>
                <div className="form-group">
                    <label>Nội dung</label>
                    <textarea className="form-control" name="" id="" rows="3"
                        value={this.state.message ?? ''} onChange={(ref) => this.setState({ message: ref.target.value })}
                    ></textarea>
                </div>

                <button type="submit" className="btn btn-primary">Lưu lại</button>
                <button type="button" className="btn btn-secondary btn-sm  float-right" onClick={this.closeForm}>Hủy</button>
            </form>}
            {!this.state.showForm && <div>
                {this.props.currentConversation.conversation_remind?.map(item => {
                    return <div key={item.id}>
                        <a href="#" onClick={() => { this.onEdit(item) }}>Gửi vào <b>{this.getSendAtText(item)}</b></a>
                        <ul>
                            <li>Ngày tạo:  {Utils.formatDateTimeShort(item.created_at)}</li>
                            <li>{item.last_message_date_diff ? 'Không có tin nhắn trong '+item.last_message_date_diff+' ngày' : 'Luôn gửi'}</li>
                            <li>Tạo bởi:  <b>{item.created_by ? item.user?.name : 'Hệ thống'}</b></li>
                        </ul>


                    </div>
                })}

                <button type="button" className="btn btn-primary" onClick={this.onAdd}>Thêm</button>

            </div>
            }
        </div>
    }
}
const mapStateToProps = state => ({
    user: state.login.user,
    currentConversation: state.conversation.currentConversation
});
const mapDispatchToProps = {
    createConversationRemind,
    delConversationRemind
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConversationRemindComponent);