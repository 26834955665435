import { LOGIN, LOGOUT } from '../';

export const logout = () => ({
  type: LOGOUT.REQUEST,
})

export const login = (email, password) => ({
  type: LOGIN.REQUEST,
  email,
  password
});

