import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import { LOGIN } from '../';
import API from '../../configs/API';
import { axiosPost } from './utils';

function* regularLogin(obj) {
  const result = yield axiosPost(API.AUTH.LOGIN, {
    email: obj.email,
    password: obj.password
  });
  
  if (result.success) {
    yield put({
      type: LOGIN.SUCCESS,
      result: result
    });

  } else {
    yield put({
      type: LOGIN.FAIL,
      result: { error: result.message }
    });
  }
}

export function* watchLogin() {
  // yield takeEvery(LOGIN_FACEBOOK, loginFacebook);
  // yield takeEvery(LOGIN_GOOGLE, loginGoogle);
  yield takeEvery(LOGIN.REQUEST, regularLogin);
}
