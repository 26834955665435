import API from './API';
import { Colors } from './Colors';
// import { Constants } from './Constants';
// import { rehydration } from '../../App';
const Configs = {
    locale: 'vi',
    date: {
        normal: 'DD/MM/YYYY',
        full: 'YYYY-MM-DD HH:mm'
    },
    hour: {
        full: 'HH:mm DD/MM/YYYY'
    }
};
const ConversationType = [
    { id: 'poh', text: 'CSKH' },
    { id: 'advise', text: 'Sales' },
    { id: 'session', text: 'Tư vấn' },
]
export { Configs, ConversationType, API, Colors };
