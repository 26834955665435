import { takeEvery, put, call, fork } from 'redux-saga/effects';
import queryString from 'query-string';
const debug = require('debug')('sagas')

import API from '../../configs/API';
import {
  GET_CONVERSATIONS_COURSES,
  GET_CONVERSATIONS, GET_CONVERSATION_DETAIL, UPDATE_CONVERSATION,
  SET_CONVERSATION_ADD_MOD, GET_MODS, GET_CONVERSATION_MOD_REMOVE,
  SEND_MESSAGE, SEND_VIDEO, SEND_IMAGE, RECEIVED_MESSAGE, ON_READ,
  GET_USER_INFO,
  POST_CREATE_CONVERSATION_REMIND, DEL_CONVERSATION_REMIND, SET_CONVERSATION_NOTE
} from '../';

import { axiosGet, axiosPost, axiosDelete } from './utils';


export function* watchConversation() {
  yield takeEvery(GET_CONVERSATION_MOD_REMOVE.REQUEST, function* (obj) {
    const result = yield axiosPost(
      API.CONVERSATION.MODS.DEL.replace("{id}", obj.id).replace('{mod_id}', obj.mod_id)
    );
    if (result.success) {
      yield put({
        type: GET_CONVERSATION_MOD_REMOVE.SUCCESS,
        id: obj.id,
        result: result
      });
    }
  });

  yield takeEvery(SET_CONVERSATION_ADD_MOD.REQUEST, function* (obj) {
    let result = yield axiosPost(API.CONVERSATION.MODS.ADD.replace('{id}', obj.params.id), {
      mod_id: obj.params.mod_id
    })
    if (result.success) {
      yield put({
        type: SET_CONVERSATION_ADD_MOD.SUCCESS,
        id: obj.id,
        result: result
      });
      if (obj.callback)
        obj.callback()
    } else {
      alert('ERROR: ' + result.message || "")
    }
  });

  yield takeEvery(GET_CONVERSATIONS.REQUEST, getConversations);
  yield takeEvery(GET_MODS.REQUEST, getModsList);
  yield takeEvery(GET_CONVERSATIONS_COURSES.REQUEST, getCoursesList);
  yield takeEvery(SEND_MESSAGE.REQUEST, sendMessage);
  yield takeEvery(RECEIVED_MESSAGE.REQUEST, receivedMessage);
  yield takeEvery(SEND_IMAGE.REQUEST, sendImageMessage);
  yield takeEvery(SEND_VIDEO.REQUEST, sendVideoMessage);
  yield takeEvery(GET_USER_INFO.REQUEST, getUserInfo);
  yield takeEvery(ON_READ.REQUEST, onRead);
  yield takeEvery(UPDATE_CONVERSATION.REQUEST, updateConversation);
  yield takeEvery(GET_CONVERSATION_DETAIL.REQUEST, getConversationDetail);
  yield takeEvery(POST_CREATE_CONVERSATION_REMIND.REQUEST, postConversationRemind);
  yield takeEvery(DEL_CONVERSATION_REMIND.REQUEST, DelConversationRemind);
  yield takeEvery(SET_CONVERSATION_NOTE.REQUEST, saveConversationNote);
}

function* saveConversationNote(obj) {

  let result = yield axiosPost(API.CONVERSATION.NOTE.replace("{id}", obj.params.id), { note: obj.params.note })
  if (result.success) {
    alert('Lưu thành công')
    yield put({
      type: SET_CONVERSATION_NOTE.SUCCESS,
      result: { note: obj.params.note }
    });
  } else {
    alert('ERROR:  ' + result.message || "")
  }
};
function* postConversationRemind(obj) {
  let result = yield axiosPost(API.CONVERSATION.REMIND.POST.replace('{id}', obj.data.conversation_id), obj.data)
  if (result.success) {
    yield put({
      type: POST_CREATE_CONVERSATION_REMIND.SUCCESS,
      result: result
    });
    if (obj.callback)
      obj.callback()
  } else {
    alert('ERROR: ' + result.message || "")
  }
};


function* DelConversationRemind(obj) {
  let result = yield axiosPost(API.CONVERSATION.REMIND.DEL.replace("{conversation_id}", obj.conversation_id).replace('{id}', obj.id))
  if (result.success) {
    yield put({
      type: POST_CREATE_CONVERSATION_REMIND.SUCCESS,
      result: result
    });
    if (obj.callback)
      obj.callback()
  } else {
    alert('ERROR: ' + result.message || "")
  }
};

function* getConversations(obj) {
  const result = yield axiosGet(API.CONVERSATION.LIST +
    '?' +
    queryString.stringify(obj.options)
  );
  if (result.success) {
    yield put({
      type: GET_CONVERSATIONS.SUCCESS,
      mode: obj.mode,
      data: result.data.conversations.data,
      paginate: { current_page: result.data.conversations.current_page, last_page: result.data.conversations.last_page },
      un_read: result.data.un_read
    });
  } else {
    yield put({
      type: GET_CONVERSATIONS.FAIL,
      result
    });
  }
}

function* getModsList() {
  const result = yield axiosGet(API.MODS.GET)

  yield put({
    type: GET_MODS.SUCCESS,
    result: result.data
  });
}

function* getCoursesList() {
  const result = yield axiosGet(API.MODS.COURSES)

  yield put({
    type: GET_CONVERSATIONS_COURSES.SUCCESS,
    result: result.data
  });
}


function* getConversationDetail(obj) {
  const { id } = obj;
  const result = yield axiosGet(API.CONVERSATION.DETAIL.replace('{id}', id));
  if (result.success) {
    yield put({
      type: GET_CONVERSATION_DETAIL.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_CONVERSATION_DETAIL.FAIL,
      result
    });
  }
}


function* updateConversation(obj) {
  const { id, params } = obj;
  const result = yield axiosGet(API.CONVERSATION.CLOSE.replace('{id}', id));
  if (result.success) {
    yield put({
      type: UPDATE_CONVERSATION.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_CONVERSATION_DETAIL.REQUEST,
      id
    });
  } else {
    yield put({
      type: UPDATE_CONVERSATION.FAIL,
      result
    });
  }
}

function* onRead(obj) {
  const { id } = obj;
  const result = yield axiosGet(API.CONVERSATION.READ.replace('{id}', id));
  if (result.success) {
    yield put({
      type: ON_READ.SUCCESS,
      result: result.data
    });
    // yield put({
    //   type: GET_CONVERSATION_DETAIL.REQUEST,
    //   id
    // });
  } else {
    yield put({
      type: ON_READ.FAIL,
      result
    });
  }
}
function* getUserInfo(obj) {
  let url = API.USER.INFO.replace('{id}', obj.id);
  const result = yield axiosGet(url, { params: obj.params });
  if (result.success) {
    yield put({
      type: GET_USER_INFO.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_USER_INFO.FAIL,
      result
    });
  }
}

function* sendMessage(obj) {
  const result = yield axiosPost(API.CONVERSATION.SEND_MESSAGE.replace('{id}', obj.id) + '?child_id=' + obj.child_id, {
    content: obj.content
  });
  if (result.success) {
    yield put({
      type: SEND_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    alert(result.message)
    yield put({
      type: SEND_MESSAGE.FAIL,
      result: { error: result.message }
    });
  }
}

function* sendImageMessage(obj) {

  var formData = new FormData();
  formData.append("image", obj.image);

  const result = yield axiosPost(API.CONVERSATION.SEND_MESSAGE.replace('{id}', obj.id) + '?child_id=' + obj.child_id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  if (result.success) {
    yield put({
      type: SEND_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: SEND_MESSAGE.FAIL,
      result
    });
  }
}

function* sendVideoMessage(obj) {

  var formData = new FormData();
  formData.append("video", obj.video);

  const result = yield axiosPost(API.CONVERSATION.SEND_MESSAGE.replace('{id}', obj.id) + '?child_id=' + obj.child_id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  if (result.success) {
    yield put({
      type: SEND_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: SEND_MESSAGE.FAIL,
      result
    });
  }
}

function* receivedMessage(obj) {
  const result = yield axiosPost(API.CONVERSATION.RECEIVED, {
    messageId: obj.messageId
  });

  if (result.success) {
    yield put({
      type: RECEIVED_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: RECEIVED_MESSAGE.FAIL,
      result
    });
  }
}


