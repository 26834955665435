const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

const createRequestTypes = base => ({
    [REQUEST]: `${base}_${REQUEST}`,
    [SUCCESS]: `${base}_${SUCCESS}`,
    [FAIL]: `${base}_${FAIL}`
});

export const LOGIN = createRequestTypes('LOGIN_REGULAR');
export const LOGOUT = createRequestTypes('LOGOUT');

export const SET_CONVERSATION_ADD_MOD = createRequestTypes('SET_CONVERSATION_ADD_MOD');
export const SET_CONVERSATION_CURRENT = createRequestTypes('SET_CONVERSATION_CURRENT');
export const GET_CONVERSATIONS = createRequestTypes('GET_CONVERSATIONS');
export const GET_CONVERSATION_MOD_REMOVE = createRequestTypes('GET_CONVERSATION_MOD_REMOVE');
export const GET_MODS = createRequestTypes('GET_MODS');
export const GET_CONVERSATIONS_COURSES = createRequestTypes('GET_CONVERSATIONS_COURSES');
export const SEND_MESSAGE = createRequestTypes('SEND_MESSAGE');
export const SEND_IMAGE = createRequestTypes('SEND_IMAGE');
export const SEND_VIDEO = createRequestTypes('SEND_VIDEO');
export const RECEIVED_MESSAGE = createRequestTypes('RECEIVED_MESSAGE');
export const GET_USER_INFO = createRequestTypes('GET_USER_INFO');
export const UPDATE_CONVERSATION = createRequestTypes('UPDATE_CONVERSATION');
export const ON_READ = createRequestTypes('ON_READ');
export const GET_CONVERSATION_DETAIL = createRequestTypes('GET_CONVERSATION_DETAIL');
export const POST_CREATE_CONVERSATION_REMIND = createRequestTypes('POST_CREATE_CONVERSATION_REMIND');
export const DEL_CONVERSATION_REMIND = createRequestTypes('DEL_CONVERSATION_REMIND');
export const SET_CONVERSATION_NOTE = createRequestTypes('SET_CONVERSATION_NOTE');