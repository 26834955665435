export const API_ROOT = 'https://api.poh.com.vn/support';
// export const API_ROOT = 'http://api.poh.chiendv.com/support';
// export const API_ROOT = 'https://dev.poh.com.vn/support';
// export const API_ROOT = 'https://api.poh.com.vn/api/v1/';
export const CDN_URL = 'https://cdn.poh.com.vn';
// export const API_ROOT = 'http://localhost:4020/';

export default {
  AUTH: {
    GOOGLE: API_ROOT + '/auth/google',
    FACEBOOK: API_ROOT + '/auth/facebook',
    LOGIN: API_ROOT + '/auth/login'
  },
  MESSAGE: {
    DELETE: API_ROOT + '/messages/{id}'
  },
  USER: {
    INFO: API_ROOT + '/users/{id}'
  },
  CONVERSATION: {
    LIST: API_ROOT + '/conversations',
    DETAIL: API_ROOT + '/conversations/{id}',
    CLOSE: API_ROOT + '/conversations/{id}/close',
    READ: API_ROOT + '/conversations/{id}/read',
    NOTE: API_ROOT + '/conversations/{id}/note',
    SEND_MESSAGE: API_ROOT + '/conversations/{id}',
    RECEIVED: API_ROOT + '/message/received',
    SEND_IMAGE: API_ROOT + '/message/upload',
    SEARCH_MESSAGE: API_ROOT + '/conversations/{id}/search',
    REMIND: {
      POST: API_ROOT + '/conversations/{id}/remind',
      DEL: API_ROOT + '/conversations/{conversation_id}/remind/{id}/del'
    },
    MODS: {
      ADD: API_ROOT + '/conversations/{id}/mod',
      DEL: API_ROOT + '/conversations/{id}/mod/{mod_id}',
    }
  },
  MODS: {
    GET: API_ROOT + '/mods',
    COURSES: API_ROOT + '/mods/courses'
  }
};
