import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { ConversationType } from '../configs';
import utils from '../utils';
import moment from '../configs/moment';
import MessageItem from './message-item'
import AvatarComponent from './avatar'

const MessageListing = ({ messages, currentUser, currentConversation, onDeleteMessage }) => <div id="messages" className="messages" style={{ outline: 'none' }}>
    {messages.map((message, index) => <MessageItem key={index} prevMessage={messages[index - 1]} nextMessage={messages[index + 1]} message={message} currentUser={currentUser} currentConversation={currentConversation} onDelete={onDeleteMessage} />)}
</div>

const MessageInputForm = ({ onSend, onKeyPress, onChange, content, onChangeImage, onSelectImage }) => {
    let image = null;
    return <form>
        {/* <div>
      <button className="btn btn-light mr-3" data-toggle="tooltip" title="" type="button" data-original-title="Emoji">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
      </button>
  </div> */}
        <TextareaAutosize className="form-control resize-ta" placeholder="Write a message." onKeyPress={onKeyPress}
            value={content} onChange={onChange}
        />
        <div className="form-buttons">
            <input type="file" ref={(ref) => image = ref} accept="image/*|video/mp4" onChange={() => { onChangeImage(image); }} style={{ display: 'none' }} />
            <button onClick={() => { image.click() }} className="btn btn-light" title="" type="button">
                <svg enableBackground="new 0 0 489.4 489.4" version="1.1" viewBox="0 0 489.4 489.4" >
                    <path d="m0 437.8c0 28.5 23.2 51.6 51.6 51.6h386.2c28.5 0 51.6-23.2 51.6-51.6v-386.2c0-28.5-23.2-51.6-51.6-51.6h-386.2c-28.5 0-51.6 23.2-51.6 51.6v386.2zm437.8 27.1h-386.2c-14.9 0-27.1-12.2-27.1-27.1v-64.5l92.8-92.8 79.3 79.3c4.8 4.8 12.5 4.8 17.3 0l143.2-143.2 107.8 107.8v113.4c0 14.9-12.2 27.1-27.1 27.1zm-386.2-440.4h386.2c14.9 0 27.1 12.2 27.1 27.1v238.1l-99.2-99.1c-4.8-4.8-12.5-4.8-17.3 0l-143.2 143.2-79.3-79.3c-4.8-4.8-12.5-4.8-17.3 0l-84.1 84.1v-287c0-14.9 12.2-27.1 27.1-27.1z" />
                    <path d="m151.7 196.1c34.4 0 62.3-28 62.3-62.3s-28-62.3-62.3-62.3-62.3 28-62.3 62.3 27.9 62.3 62.3 62.3zm0-100.1c20.9 0 37.8 17 37.8 37.8s-17 37.8-37.8 37.8-37.8-17-37.8-37.8 16.9-37.8 37.8-37.8z" />
                </svg>
            </button>
            {/* <button className="btn btn-light d-sm-none d-block" data-toggle="tooltip" title="" type="button" data-original-title="Send a voice record">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mic"><path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg>
      </button> */}
            <button className="btn btn-primary" onClick={onSend}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
            </button>
        </div>
    </form>
}

const ConversationListing = ({ }) => {

}

const ConversationListingItem = props => {
    const { conversation, index, currentConversation, onClick } = props;

    const renderFormatConversationType = (conversation) => {
        let item = ConversationType.filter(item => item.id == conversation.type)[0]
        return <span className="badge badge-info">{item.text}</span>
    }
    const formatBadge = () => {
        if (conversation.last_message_from != null && conversation.last_message_from != currentConversation.user_id)
            return 'badge badge-pill badge-danger'
        return 'badge badge-pill badge-secondary'
    }
    const formatName = () => {
        if (conversation.course_id)
            return conversation.user.name + ' - ' + conversation.name
        return conversation.name
    }

    const formatCell = () => {
        let classes = ['list-group-item'];
        if (conversation.id == currentConversation.id)
            classes.push('active-chat')
        if (conversation.mess_after_mod)
            classes.push('open-chat')

        return classes.join(' ');
    }

    const formatLabels = () => {
        const labels = []
        if (conversation.conversation_level) {
            if (conversation.conversation_level == 'premium')
                labels.push(<div className='vip'></div>)
        }
        if (moment().diff(moment(conversation.created_at), 'days') < 30) {
            console.log(moment(conversation.created_at))
            labels.push(<div className='new'></div>)
        }
        return labels
    }

    return <li
        className={formatCell()}
        onClick={() => onClick(conversation, index)}
    >
        <div className='labels'>{formatLabels()}</div>
        <div className="avatar">
            <AvatarComponent user={conversation.user} />
            <figure>
                {renderFormatConversationType(conversation)}
                <div className='small mt-1'>#{conversation.id}</div>
            </figure>
        </div>
        <div className="users-list-body">
            <div>
                <h5>{formatName()}</h5>
                <p style={{ color: '#000' }}>{conversation.last_message}</p>
                <div>
                    {conversation.wait_from_time && "Đợi: " + utils.formatDateFromNow(conversation.wait_from_time)}
                    <span className='float-right text-success'>{utils.formatDateFromNow(conversation.updated_at)}</span>
                </div>
                <div className="mt-1 text-right">
                    <span>{conversation.status == 'close' && <i className="float-right text-success">Đã đóng</i>}</span>
                    <b>{conversation.last_mod?.name}</b>
                </div>
            </div>
        </div>
        {conversation.mess_after_mod > 0 && <div className='mt-4'>
            <span className={formatBadge()}>{conversation.mess_after_mod}</span>
        </div>}
    </li>
}

const ConversationFilter = ({ onSubmit, onChange, courses, mods, onBtnSearchClick, keyword }) => {
    const submitHandler = (e) => {
        e.preventDefault();
        onBtnSearchClick()
    }

    return <form className="container" onSubmit={submitHandler}>
        <div className="form-group">
            <div className="row">
                <div className="col-md-6">
                    <select className="form-control" onChange={ref => onChange({ type: ref.target.value })}>
                        <option value=''>Tất cả Hội thoại</option>
                        {ConversationType.map((item, key) =>
                            <option key={key} value={item.id}>{item.text}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-6">
                    <select className="form-control" onChange={ref => onChange({ mod_id: parseInt(ref.target.value) })}>
                        <option value='0'>Tất cả Mod</option>
                        {mods && mods.map((mod, key) =>
                            <option key={key} value={mod.id}>{mod.name}</option>
                        )}
                    </select>
                </div>
            </div>
        </div>
        <div className="form-group">
            <select className="form-control" onChange={ref => onChange({ course_id: parseInt(ref.target.value) })}>
                <option value='0'>-- Tất cả Khóa học --</option>
                <optgroup label="Khóa học đang kích hoạt">
                    {courses && courses.filter(item => item.status == 'active').map((course, key) =>
                        <option key={key} value={course.id}>{course?.short_name || course.name}</option>
                    )}
                </optgroup>
                <optgroup label="Khóa học không kích hoạt">
                    {courses && courses.filter(item => item.status != 'active').map((course, key) =>
                        <option key={key} value={course.id}>{course?.short_name || course.name}</option>
                    )}
                </optgroup>
            </select>
        </div>
        <div className="input-group">
            <input type="text" value={keyword} className="form-control mr-3" placeholder="@ID, Tên, Email, Số điện thoại" onChange={ref => onChange({ search: ref.target.value })} />
            <button className="btn btn-primary" type="button" onClick={() => onBtnSearchClick()}>Search</button>
        </div>
    </form>
}

const ConversationHeader = ({ currentConversation, isAllowToReply, onRead, renderStatus }) => {
    return [
        <div className="chat-header-user">
            <AvatarComponent user={currentConversation.user} />
            <div>
                <h5>{currentConversation.user.name} / {currentConversation?.name} ({currentConversation?.children?.birthday})</h5>
                <h6 className='mt-2'><i className="text-success">{renderStatus(currentConversation)}</i> #{currentConversation?.id}  - <small>POH ID:</small> {currentConversation.user?.id}</h6>
            </div>
        </div>,
        isAllowToReply && <div className="chat-header-action text-right">
            <ul className="list-inline">
                <li className="list-inline-item">
                    {currentConversation &&
                        <a className="btn btn-info" href="#" onClick={onRead}>
                            <i className="las la-check-square mr-1"></i>
                        </a>}
                </li>
                <li className="list-inline-item">
                    <a href="#" className="btn btn-outline-light" data-navigation-target="contact-information" aria-expanded="false">
                        <i className="las la-user"></i>
                    </a>
                </li>
            </ul>
        </div>
    ]
}
export { MessageInputForm, MessageListing, ConversationListing, ConversationListingItem, ConversationFilter, ConversationHeader }