import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../redux/actions/login';
import { Redirect } from 'react-router-dom';

import LoadingComponent from '../../components/loading';

const classes = {
  formSignin: {
    width: "100%",
    maxWidth: "420px",
    padding: "15px",
    margin: "0 auto",
  }
}

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      email: '',
      password: ''
    }
    this.onLogin = this.onLogin.bind(this);
  }

  onLogin() {
    this.setState({
      showError: false
    })
    this.props.login(this.state.email, this.state.password);
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.loginSuccess == null && this.props.loginSuccess !== prevProps.loginSuccess) {
      this.setState({
        showError: true,
        message: this.props.errorMessage
      })
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onLogin();
    }
  }

  render() {

    if (this.props.user) {
      return <Redirect to="/" />;
    }

    return [
      this.props.showLoading && <LoadingComponent key="loading" />,
      <form style={classes.formSignin} key="login_form">
        <div className="text-center mb-4">
          <img className="mb-4" src="/images/logo.png" alt="" height="72" />
          <h1 className="h3 mb-3 font-weight-normal">Login</h1>
        </div>
        {this.state.showError &&
          <div className="alert alert-danger" role="alert">
            {this.state.message}
          </div>}
        <div className="form-label-group">
          <label htmlFor="inputEmail">Email address</label>
          <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autoFocus
            autoComplete="email"
            onChange={ref => this.setState({ email: ref.target.value })} onKeyDown={this._handleKeyDown} />
        </div>

        <div className="form-label-group">
          <label htmlFor="inputPassword">Password</label>
          <input type="password" id="inputPassword" className="form-control" required
            autoComplete="current-password"
            onChange={ref => this.setState({ password: ref.target.value })} onKeyDown={this._handleKeyDown} />
        </div>

        <div className="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label>
        </div>
        <button className="btn btn-lg btn-primary btn-block" type="button" onClick={this.onLogin}>Sign in</button>
        <p className="mt-5 mb-3 text-muted text-center">POH &copy; 2019</p>
      </form>];
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  loginSuccess: state.login.success,
  errorMessage: state.login.errorMessage,
  showLoading: state.login.showLoading
});
const mapDispatchToProps = {
  login
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);