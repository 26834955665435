import React, { Component } from 'react';
import localforage from 'localforage';

const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    localforage.clear()
    alert('Complete Cache Cleared')
};

export default class ErrorBoundary extends React.Component {
    constructor(props) {

        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {

        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError
            // && process.env.NODE_ENV == 'production'
        ) {
            // You can render any custom fallback UI
            return <div className="mx-auto" style={{ 'width': "600" }}>
                <h1 className="text-danger">Oops! Lỗi rồi...</h1>
                <h3>Thử <span
                    className="btn btn-primary btn-xs"
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    reload
                </span> lại trang xem sao, </h3>
                <h4>Hoặc <span
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                        clearCacheData();
                        window.location.reload();
                    }}
                >xóa cache</span> rồi thử lại</h4>
                <h6 className="text-muted">Nếu vẫn không được thì gọi cứu viện đi</h6>
            </div>;
        }

        return this.props.children;
    }
}