import React, { Component } from 'react';
import { connect } from 'react-redux';

import Utils from '../../utils';
import ConversationListComponent from '../../components/conversation-list'
import ConversationDetail from './detail';
import Profile from '../../components/Profile';

import { firebase, onListenFirestore } from '../../helpers/firebase';
import { getConversations, setCurrentConversation, sendMessage, getCoursesList, getModsList } from '../../redux/actions/action_conversation';


import '../Conversation/custom_conversation.css';
import moment from '../../configs/moment';
import LoadingComponent from '../../components/loading';
const debug = require('debug')('conversation:list')
const debug_permission = require('debug')('permission:list')

import { ConversationFilter } from "../../components/conversation"

export class Conversation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        page: 1,
        mod_id: null,
        course_id: null,
        type: null,
      },
      mod_courses: []
    };

    this.updateMessages = this.updateMessages.bind(this);
  }

  componentDidMount() {
    this.props.getCoursesList();
    this.props.getModsList();

  }
  componentDidUpdate(prevProps, prevState) {
    // const { currentConversation, currentUser } = this.props


    $('[data-toggle="popover"]').popover()
  }

  onLoadMore = (page) => {
    this.onSearch('more', { page: page });
  }

  onSearch = (mode = false, params) => {

    var filter = { ...this.state.filter, ...params }
    this.setState({ filter: filter })

    this.props.getConversations(mode, filter);
    this.updateCheckpoint();
  }

  onBtnSearchClick = () => {
    this.onSearch(false, {
      search: this.state.search,
      page: 1,
      type: this.state.type,
      mod_id: this.state.mod_id,
      course_id: this.state.course_id,
    });
  }

  updateCheckpoint = () => {
    this.setState({ checkpoint: moment().format('YYYY-MM-DD HH:mm:ss') })
  }

  updateMessages(messages) {
    this.setState({ messages })
  }

  isCourseTeacher = (user, course_id) => {
    let mod = this.props.mods.filter(item => item.id == user.id)[0]
    return mod.courses.filter(item => {
      return (item.course_id == course_id && item.role == 'teacher')
    }).length ? true : false
  }

  isUserRole = (role) => {
    return this.props.currentUser.role_user == role
  }

  isAllowToAddMod = () => {
    if (Utils.hasPermission('support.add_remove_mod_all', this.props.permissions)) {
      debug_permission('isAllowToAddMod', 'support.add_remove_mod_all', true)
      return true
    }
    if (Utils.hasPermission('support.conversation_mod_add', this.props.permissions)) {
      // if (this.state.mod_courses.filter(course => course.course_id == this.props.currentConversation.course_id).length) {
      debug_permission('isAllowToAddMod', 'support.conversation_mod_add', true)
      return true
      // }
    }
    return false
  }

  isAllowToSelfAdd = () => {
    if (Utils.hasPermission('support.self_add_all', this.props.permissions)) {
      debug_permission('isAllowToSelfAdd', 'support.self_add_all', true)
      return true;
    }
    if (Utils.hasPermission('support.self_add', this.props.permissions)) {
      if (this.state.mod_courses.filter(course => course.course_id == this.props.currentConversation.course_id).length) {
        debug_permission('isAllowToSelfAdd', 'support.self_add', true)
        return true
      }
    }
    return false
  }

  isAllowToRemoveMod = () => {
    if (Utils.hasPermission('support.add_remove_mod_all', this.props.permissions)) {
      debug_permission('isAllowToRemoveMod', 'support.add_remove_mod_all', true)
      return true
    }
    if (Utils.hasPermission('support.conversation_mod_del', this.props.permissions)) {
      // if (this.state.mod_courses.filter(course => course.course_id == this.props.currentConversation.course_id).length) {
      debug_permission('isAllowToRemoveMod', 'support.conversation_mod_del', true)
      return true
      // }
    }
    return false
  }


  isAllowToReply = () => {
    const { currentConversation, currentUser } = this.props
    // console.log('isAllowToReply',currentConversation.users,currentUser.id)
    if (currentConversation.type == 'poh' && Utils.hasPermission('support.self_add_poh', this.props.permissions)) {
      debug_permission('isAllowToReply', 'support.self_add_poh', true)
      return true
    }
    if (currentConversation.type == 'advise' && Utils.hasPermission('support.self_add_sale', this.props.permissions)) {
      debug_permission('isAllowToReply', 'support.self_add_sale', true)
      return true
    }
    if (currentConversation.users?.filter(item => item.user_id == currentUser.id).length) {
      debug_permission('isAllowToReply', 'currentConversation.users', true)
      return true
    }
    return false
  }

  filterMods = () => {
    const { currentConversation, mods } = this.props
    // return mods
    return mods.filter(mod => {
      if (mod.role?.code == 'admin')
        return mod;
      if (mod.role?.code == 'sale' && currentConversation.type == 'advise')
        return mod;
      if (mod.role?.code == 'cskh' && currentConversation.type == 'poh')
        return mod;
      if ((mod.role?.code == 'mod' || mod.role?.code == 'teacher') && currentConversation.type == 'session') {
        return mod.mod_courses.filter(course => course.course_id == currentConversation.course_id).length ? mod : false
      }


    });
  }

  onFilterChanged = (params) => {
    this.setState(params)
  }

  buildQueryParams = () => {
    const { currentConversation } = this.props;
    let params = { child_id: this.props.currentConversation.child_id }
    if (currentConversation.course_id != null)
      params.schedule = true;
    return params
  }

  render() {
    // const { conversations, myList, mods, courses, current } = this.props;

    return (
      <div className="content">
        {this.props.isFetching && <LoadingComponent />}
        <div className="sidebar-group mt-3">
          <div id="chats" className="sidebar active">
            <ConversationFilter onSubmit={this.onSubmit} onChange={this.onFilterChanged} courses={this.props.courses} mods={this.props.mods} onBtnSearchClick={this.onBtnSearchClick} keyword={this.state.keyword} />
            <ConversationListComponent
              onSearch={this.onSearch}
              filter={this.state.filter}
              // conversations={conversations}
              // current={current}
              // myList={myList}
              onLoadMore={this.onLoadMore} />
          </div>
        </div>
        {
          this.props.currentConversation && <ConversationDetail
            buildQueryParams={this.buildQueryParams}
            updateMessages={this.updateMessages}
            isAllowToSelfAdd={this.isAllowToSelfAdd()}
            isAllowToReply={this.isAllowToReply()}
          />
          ||
          <div className="chat">
            <div id="chat-body" className="chat-body" tabIndex="1" style={{ overflow: 'hidden', outline: 'none' }}>

              <div className="no-message-container">
                <div className="row mb-5">
                  <div className="col-md-4 offset-4">
                    <img src="./assets/undraw_empty_xct9.svg" className="img-fluid" alt="image" />
                  </div>
                </div>
                <p className="lead text-center">Select a chat to read messages</p>
              </div>
            </div>
          </div>
        }
        {this.props.currentConversation && <Profile
          filterMods={this.filterMods}
          isAllowToAddMod={this.isAllowToAddMod()}
          isAllowToRemoveMod={this.isAllowToRemoveMod()}
        />}
      </div >
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.login.user,
  isFetching: state.conversation.isFetching,
  // conversations: state.conversation.conversations,
  // myList: state.conversation.myList,
  // // isUpdatingConversation: state.conversation.isUpdatingConversation,
  currentConversation: state.conversation.currentConversation,
  permissions: state.login.permissions,
  mods: state.conversation.mods,
  courses: state.conversation.courses,
  // current: state.conversation.current
});
const mapDispatchToProps = {
  // setCurrentConversation,
  getConversations,
  getModsList,
  getCoursesList,
  // sendMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conversation);