import { all, fork } from 'redux-saga/effects';
import { watchLogin } from './login';
import { watchConversation } from './saga_conversation';

export default function* sagas() {
  yield all([
    fork(watchLogin),
    fork(watchConversation),
    
  ]);
}
