import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Drawer from './Drawer';

export default class Home extends Component {

    render() {
        return <Redirect to='/conversation' />
        return <div className="container" style={{ marginTop: '80px' }}>
            Hello
        </div>
    }
}