import axios from 'axios';
const debug = require('debug')('reducers:login')

import { LOGIN, LOGOUT } from '../';

const initialState = {
  isLogged: false,
  showLoading: false,
  success: null,
  token: null,
  user: null,
  userId: 0,
  permissions: [],
  errorMessage: null
};

export default (state = initialState, action) => {

  if (action.type.endsWith('_FAIL')) {
    if (
      action.result.errorMessage &&
      action.result.errorMessage.status === 401 &&
      state.userId !== 0
    ) {
      return initialState;
    }
  }
  switch (action.type) {
    case LOGOUT.REQUEST:
      axios.defaults.headers.common.Authorization = null;
      return initialState;

    case LOGIN.REQUEST:
      return { ...state, showLoading: true, isLogged: false, success: null };

    case LOGIN.SUCCESS:
      axios.defaults.headers.common.Authorization = 'Bearer ' + action.result.token;
      return {
        ...state,
        isLogged: true,
        showLoading: false,
        success: true,
        token: action.result.token,
        user: action.result.user,
        permissions: action.result.permissions,
        roles: action.result.roles,
        userId: action.result.user.id
      };

    case LOGIN.FAIL:
      return {
        ...state,
        isLogged: false,
        showLoading: false,
        success: false,
        errorMessage: action.result.error
      };

    default:
      return state;
  }
};
